import React, { Suspense, useEffect, useState } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { CSpinner, useColorModes } from '@coreui/react'
import { ClaimDataProvider } from './context/claimContext'
import './scss/style.scss'
import AuthProvider from './auth/authProvider'
import ProtectedRoute from './views/protectedRoute'

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const App = () => {
  const { isColorModeSet, setColorMode } = useColorModes('coreui-free-react-admin-template-theme')
  const storedTheme = useSelector((state) => state.theme)
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      console.log(window.innerHeight)
      console.log(window.innerWidth)
      setViewportHeight(window.innerHeight);
      setViewportWidth(window.innerWidth);
    };

    // Set the initial height
    handleResize();

    // Listen for resize events
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  useEffect(() => {
    setColorMode('light')
    // const urlParams = new URLSearchParams(window.location.href.split('?')[1])
    // const theme = urlParams.get('theme') && urlParams.get('theme').match(/^[A-Za-z0-9\s]+/)[0]
    // console.log(theme)
    // if (theme) {
    //   setColorMode(theme)
    // }

    // if (isColorModeSet()) {
    //   return
    // }

    // setColorMode(storedTheme)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (  
    <div style={{
      height: `${viewportHeight}px`,
      width: `${viewportWidth}px`,
      // backgroundColor: 'lightgray',
      display: 'flex',
      flexDirection: 'column',
      // borderStyle: 'solid',
      // borderWidth: '2px',
      // borderColor: 'red'
    }}>
    <HashRouter>
      <AuthProvider>
        <ClaimDataProvider>
        <Suspense
          fallback={
            <div className="pt-3 text-center">
              <CSpinner color="primary" variant="grow" />
            </div>
          }>
          <Routes>
            <Route exact path="/login" name="Login Page" element={<Login />} />
            <Route element={<ProtectedRoute />}>
              <Route path="*" name="Home" element={<DefaultLayout />} />
            </Route>
            {/* <Route exact path="/register" name="Register Page" element={<Register />} />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} /> */}
            
              
          </Routes>
        </Suspense>
        </ClaimDataProvider>
      </AuthProvider>
    </HashRouter>
    </div>
  )
}

export default App
