// import { newIdeaTemplate } from "src/context/IdeasContext";

const dataReducer = (state, action) => {
    const newstate = { ...state }
    console.log(state)
    switch (action.type) {
        // case 'GET_PROMPTS_LIST':
        //     const prompts = {}
        //     return { ...state, prompts: action.payload }
        // case 'PROMPTS_LOADED':
        //     return { ...state, prompts_loaded: true };
        // case 'UPDATED_PROMPT':
        //     const exists = newstate.prompts.filter(f=>f.id==action.payload.id)
        //     console.log(exists)
        //     if(exists && exists.length){
        //         newstate.prompts = newstate.prompts.map(m=>m.id==action.payload.id ? action.payload : m)
        //     }
        //     else{
        //         newstate.prompts.push(action.payload)
        //     }
        //     console.log(newstate.prompts)
        //     return { ...state, prompts: newstate.prompts };
        // case 'DELETE_PROMPT':
        //     newstate.prompts = newstate.prompts.filter(f=>f.id!=action.payload)
        //     return newstate
        // case 'INSERTING_IDEA_START':
        //     return { ...state, inserting_idea_key: action.payload }
        // case 'INSERTING_IDEA_END':
        //     return { ...state, inserting_idea_key: null }
        // case 'SET_SETTINGS': 
        //     return { ...state, settings: action.payload }
        // case 'GET_PITCH_LIST':
        //     const pitches = {}
        //     action.payload.forEach((el) => pitches[el.id] = el);
        //     return { ...state, pitches: pitches }
        // case 'GET_IDEAS_LIST':
        //     const ideas = {}
        //     action.payload.forEach((el) => ideas[el.id] = {seed: el});
        //     return { ...state, ideas: ideas }
        // case 'IDEAS_LOADED':
        //     return { ...state, ideas_loaded: true };
        // case 'NEW_IDEA':
        //     newstate.ideas[action.payload.seed.id] = action.payload
        //     newstate.ideas[action.payload.seed.id].fully_loaded = true
        //     return newstate
        // case 'UPDATE_IDEA':
        //     // newstate.ideas = newstate.ideas.map
        //     if(!(action.payload.seed.id in newstate.ideas)){
        //         newstate.ideas[action.payload.seed.id] = newIdeaTemplate()
        //     }
        //     newstate.ideas[action.payload.seed.id] = action.payload
        //     // newstate.ideas[action.payload.seed.id].fully_loaded = true
        //     return newstate
        // case 'UPDATE_PITCH':
        //     newstate.pitches[action.payload.id] = action.payload
        //     return newstate
        // case 'DELETE_IDEA':
        //     console.log(newstate)
        //     delete newstate.ideas[action.payload]
        //     console.log(newstate)
        //     return newstate
        // case 'DELETE_PITCH':
        //     console.log(newstate)
        //     delete newstate.pitches[action.payload]
        //     console.log(newstate)
        //     return newstate
        // case 'DELETE_OPTION':
        //     console.log(newstate)
        //     newstate.ideas[action.payload.ideaId].options = newstate.ideas[action.payload.ideaId].options.filter(f=>f.id!=action.payload.optionId)
        //     console.log(newstate)
        //     return newstate
        // case 'GET_IDEA':
        //     console.log(action.payload)
        //     action.payload.fully_loaded = true
        //     newstate.ideas[action.payload.seed.id] = action.payload
        //     return newstate
        // case 'UPDATE_IDEA_OPTIONS':
        //     console.log(action.payload.idea_id)
        //     console.log(action.payload.options)
        //     newstate.ideas[action.payload.idea_id].options = action.payload.options
        //     return newstate
        // case 'UPDATE_IDEA_DRAFT':
        //     newstate.ideas[action.payload.idea_id].draft = action.payload
        //     return newstate
        // case 'EDITING_SEED':
        //     newstate.ideas[action.payload.idea_id].editing_seed = action.payload.editing_seed
        //     return newstate
        // case 'INSERT_EMPTY_IDEA':
        //     console.log(action.payload)
        //     newstate.ideas[action.payload] = newIdeaTemplate()
        //     console.log(newstate)
        //     return newstate
        // case 'DELETE_EMPTY_IDEA':
        //     delete newstate.ideas[null]
        //     return newstate
        // case "CUSTOMIZING_OPTION":
        //     console.log(action.payload)
        //     newstate.ideas[action.payload.ideaId].customizing_option = action.payload.optionId
        //     return newstate
        // case "CUSTOM_UPDATED":
        //     const newOptions = newstate.ideas[action.payload.ideaId].options.map(m=>m.id==action.payload.option.id ? action.payload.option : m)
        //     newstate.ideas[action.payload.ideaId].options = newOptions
        //     newstate.ideas[action.payload.ideaId].customizing_option = null
        //     return newstate
        // // case 'GET_IDEA_OPTIONS':
        // //     newstate.instructions[action.payload.id]['remove'] = true
        // //     return newstate
        //     // case 'REMOVE_IDEA':
        //     //     delete newstate.instructions[action.payload.id]
        //     //     // return { ...state,
        //     //     //     instructions: state.instructions.filter(i => i.id!=action.payload.id)
        //     //     // };
        //     //     return newstate
        // //   case 'ADD_READERS':
        // //     return { ...state, readers: action.payload };
        //     // You can add more cases to handle different data types and actions
        default:
            return state
}};
  
export default dataReducer
