import React, { useReducer, createContext, useContext, useEffect } from 'react'
import dataReducer from '../reducers/dataReducer'
import { PropTypes } from 'prop-types'

const ClaimContext = createContext()

export const ClaimDataProvider = ({ children }) => {
  const [claimData, dispatch] = useReducer(dataReducer, {})

  // useEffect(() => {
  //   async function fetchInitialData() {
  //     try {
  //       fetchSettingsFromAPI().then(
  //         settings => {
  //           dispatch({ type: 'SET_SETTINGS', payload: settings })
  //         }
  //       ).then(
  //         fetchIdeasFromAPI().then(
  //           ideas => {
  //             dispatch({ type: 'GET_IDEAS_LIST', payload: ideas })
  //             dispatch({ type: 'IDEAS_LOADED' })
  //           }
  //         )
  //       )
        
  //     } catch (error) {
  //       console.error(error)
  //     }
  //   }
  //   fetchInitialData()
  // }, [])

  return <ClaimContext.Provider value={{ claimData, dispatch }}>{children}</ClaimContext.Provider>
}

ClaimDataProvider.propTypes = {
  children: PropTypes.object
}

export function useData() {
  return useContext(ClaimContext)
}
