import React, { useContext, createContext, useState } from "react";
import { PropTypes } from 'prop-types'
import axios from "axios";
import { useNavigate } from "react-router-dom";
const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [screen, setScreen] = useState("auth");

  const loginAuth =  (data) => {
    if (data.password=='mecanica'){
        setScreen("dashboard");
        navigate("/dashboard");
        console.log("return true")
        return true
    }
    console.log("return false")
    return false
  };

//   const readCookie = async () => {
//       setScreen("dashboard");
//       navigate("/dashboard");
//       return
//   };

  const logoutAuth = async () => {
      setScreen("auth");
      navigate("/login");
      return
  };

  return (
    <AuthContext.Provider value={{ screen, loginAuth, logoutAuth }}>
      {children}
    </AuthContext.Provider>
  );
};
AuthProvider.propTypes = {
    children: PropTypes.object
  }

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};